<template>
  <div id="admin-dashboard-analytics">
    <div class="vx-row">
      <div class="vx-col w-full lg:w-1/2 mb-base">
        <h2>{{$t('Overview')}}</h2>
      </div>
    </div>
    <div class="vx-row">
      <div class="vx-col w-full lg:w-1/4 mb-base" v-for="(khazna, index) in warehousesNotVirtual" :key="index">
        <a class="cursor-pointer" @click="warehouseKhazna(khazna.code)">
          <statistics-card-line
            hideChart
            :dataLoading="warehousesNotVirtualLoading"
            icon="DatabaseIcon"
            :statistic="khazna.balance ? khazna.balance.toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2}) + ' ' + $t('EGP') : '0.00 ' + $t('EGP')"
            :statisticTitle="khazna.name"
          ></statistics-card-line>
        </a>
      </div>
    </div>
  </div>
</template>
<script>
import StatisticsCardLine from '@/components/statistics-cards/StatisticsCardLine.vue'
import {sendRequest} from '../../http/axios/requestHelper.js'
import common  from '@/assets/utils/common'

export default {
  data () {
    return {
      warehousesNotVirtual: [],
      warehousesNotVirtualLoading: false
    }
  },
  methods: {
    formatWarehouses (warehouses) {
      warehouses.forEach(warehouse => {
        if (!warehouse.is_virtual) {
          this.warehousesNotVirtual.push({
            id: warehouse.id,
            name: warehouse.name,
            code: warehouse.code,
            balance: 0
          })
        }
      })
      this.loadKhazna()
    },
    loadKhazna () {
      this.warehousesNotVirtual.forEach(warehouse => {
        this.warehousesNotVirtualLoading = true
        sendRequest(true, false, this, `api/v1/accounting/khazna/${warehouse.id}/`, 'get', null, true, 
          (response) => {
            warehouse.balance = response.data.balance
            this.warehousesNotVirtualLoading = false
          }
        )
      })
    },
    warehouseKhazna (warehouseID) {
      this.$router.push({
        name: 'finance-operations-warehouses-khazna',
        params: {
          warehouseID
        }
      }).catch(() => {})
    }
  },
  components: {
    StatisticsCardLine
  },
  created () {
    common.manageLoadWarehouses(this.formatWarehouses, this)
  }
}
</script>